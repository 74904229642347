/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Theme elements CSS */
@import "theme/input.scss";

/*
 * --------------
 * Fonts
 * --------------
 */

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url('../src/assets/fonts/PulpDisplay-ExtraBold.woff') format('woff');
  font-family: 'Pulp Display ExtraBold';
  font-weight: 900;
}

@font-face {
  src: url('../src/assets/fonts/PulpDisplay-Bold.woff') format('woff');
  font-family: 'Pulp Display Bold';
  font-weight: 700;
}

@font-face {
  src: url('../src/assets/fonts/PulpDisplay-Regular.woff') format('woff');
  font-family: 'Pulp Display Regular';
  font-weight: 400;
}

@font-face {
  src: url('../src/assets/fonts/Gayathri-Regular.ttf') format('ttf');
  font-family: 'Gayathri';
}
@font-face {
  src: url('../src/assets/fonts/Roboto-Regular.ttf') format('ttf');
  font-family: 'Roboto';
}


/*
 * --------------
 * Colors
 * --------------
 */

 $primary: #2D2D2D;
 $light-blue: #F8F9FD;
 $medium-blue: #e0e9fe;
 $blue: #407BFF;
 $grey: #7a888a;
 $dark-blue:#00092F;
 $bg-white:#ffffff;

 /********************/
 $body-color: #F0F2F3;

.title-1 {
  font-family: 'Gayathri', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  color: #000000;

  margin: 0;
}

.title-2 {
  @extend .title-1;
  font-size: 24px;
  line-height: 29px;
}

.title-3 {
  @extend .title-1;
  font-size: 18px;
  line-height: 22px;
}

.p1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  margin: 0;
}

.p2 {
  @extend .p1;
  font-size: 14px;
  line-height: 140%;
}

.p3 {
  @extend .p1;
  font-size: 12px;
  line-height: 14px;
}

.text-grey {
  color: #767676 !important;
}

ion-loading.custom-loading {
  --background: #ffffff;
  --min-width: 400px;
  --min-height: 100%;
  --spinner-color: #000000;

  .loading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 24px;

    margin: 0 auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    ion-spinner {
      height: 52px;
      width: 52px;
    }

    .loading-content {
      font-family: 'Gayathri', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      color: #000000;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }
}

textarea {
  overflow: hidden;
}

.primary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;

  width: 100%;
  height: 44px;

  --background: #000000;
  --background-activated: #222428;
  --background-focused: #222428;
  --background-hover: #222428;
  color: #FFFFFF;

  text-align: center;
}

.primary-btn:disabled, .primary-btn[disabled] {
  --background: #E2E2E2;
  color: #A4A4A4;
}

.secondary-btn {
  @extend .primary-btn;

  --background: #F5F5F5;
  --background-activated: #F0F0F0;
  --background-focused: #F0F0F0;
  --background-hover: #F0F0F0;
  color: #000000;
}

.tertiary-btn {
  @extend .primary-btn;

  --background: #F5F5F5;
  --background-activated: #FFFFFF;
  --background-focused: #FFFFFF;
  --background-hover: #FFFFFF;
  color: #000000;

  --border-radius: 64px;
  --border-color: #000000;
  --border-style: solid;
  --border-width: 1px;
}

.tertiary-btn:disabled, .tertiary-btn[disabled] {
  --background: #E2E2E2;
  color: #A4A4A4;

  --border-style: none;
}

.greyed-out-btn {
  @extend .primary-btn;

  --background: #E2E2E2;
  --background-activated: #E2E2E2;
  --background-focused: #E2E2E2;
  --background-hover: #E2E2E2;
  color: #444444;
}

.transparent-btn {
  @extend .tertiary-btn;

  height: 20px;

  --background: rgb(255, 255, 255, 0);
  --background-activated: rgb(255, 255, 255, 0);
  --background-focused: rgb(255, 255, 255, 0);
  --background-hover: rgb(255, 255, 255, 0);

  --padding-top: 0;
  --padding-bottom: 0;
  --border-width: 0;
}

.small-btn {
  width: 60px;
  height: 32px;
}

.tiny-btn {
  width: 24px;
  height: 20px;
}

.low-btn {
  height: 32px;
}

.white-btn {
  --background: #FFFFFF;
  --background-activated: #F5F5F5;
  --background-focused: #F5F5F5;
  --background-hover: #F5F5F5;
}

input.searchbar-input.sc-ion-searchbar-ios  {
  font-size: 14px;
  padding-left: 0 !important;
}

ion-searchbar.searchbar-has-focus {
  border: 1px solid #000000 !important;
}

ion-input.text-input {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;

  --padding-start: 20px;
  --padding-top: 13px;
  --padding-bottom: 12px;
  --padding-end: 0;
  padding-right: 10px !important;

  width: 100%;
  height: 44px;

  --background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 900px;

  font-size: 14px;

  &.has-focus {
    border: 1px solid #000000 !important;
  }
}

ion-img.ingredient-image::part(image) {
  border: 1.5px solid #FFFFFF;
  border-radius: 4px;
}

ion-img.ingredient-image.selected::part(image) {
  border-color: #000000;
}
